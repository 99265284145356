import React, { useState } from 'react';
import './styles/Navigation.css';

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navigation">
      <div className="nav-left">
        <button className="hamburger" onClick={toggleMenu}>
          ☰
        </button>
        <h1>Adiba Akter</h1>
      </div>
      <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <a href="#home" onClick={() => setIsMenuOpen(false)}>Home</a>
        <a href="#about" onClick={() => setIsMenuOpen(false)}>About</a>
        <a href="#experience" onClick={() => setIsMenuOpen(false)}>Experience</a>
        <a href="#projects" onClick={() => setIsMenuOpen(false)}>Projects</a>
        <a href="#contact" onClick={() => setIsMenuOpen(false)}>Contact</a>
      </div>
      <div className="nav-right">
        <a href="https://github.com/AdibaAdi" target="_blank" rel="noopener noreferrer" className="button-link">GitHub</a>
        <a href="https://www.linkedin.com/in/adiba-akter-9b025222b/" target="_blank" rel="noopener noreferrer" className="button-link">LinkedIn</a>
      </div>
    </nav>
  );
}

export default Navigation;
