import React from 'react';
import Navigation from './components/Navigation';
import AboutMe from './components/AboutMe';
import Achievements from './components/Achievements';
import Projects from './components/Projects';
import Experience from './components/Experience';
import HonorsAndAwards from './components/HonorsAndAwards';
import Contact from './components/Contact';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navigation />
      <main>
        <AboutMe />
        <Achievements />
        <Experience />
        <Projects />
        <HonorsAndAwards />
        <Contact />
      </main>
    </div>
  );
}

export default App;
