import React from 'react';
import './styles/Experience.css';

function Experience() {
  return (
    <section className="experience" id="experience">
      <h2>Experiences</h2>
      <div className="experience-cards">
        <div className="experience-card">
          <h3>California Department of Toxic Substances</h3>
          <p><strong>Solutions Developer Intern (SAAS ArcGIS)</strong> | May 2024 - Present</p>
          <ul>
            <li>Transformed indoor cubicle layouts for two facilities using Python, ArcGIS Pro, and data visualization, increasing workstation reservation rates by 90%.</li>
            <li>Conducted rigorous testing to ensure quality and accuracy of spatial layouts and data visualization.</li>
            <li>Enhanced web experience maps with real-time fire and traffic tracking, improving situational awareness for stakeholders.</li>
            <li>Developed Python scripts for waste management in wildfire-affected areas, boosting site visibility and survey connectivity by 80%.</li>
          </ul>
        </div>
        <div className="experience-card">
          <h3>Sac State Enrollment Management and Student Services</h3>
          <p><strong>Student Assistant</strong> | April 2022 – August 2024</p>
          <ul>
            <li>Resolved technical issues related to student enrollment data, managing tickets in EAB and developing dashboards in Excel and Tableau.</li>
            <li>Created and refined data queries to analyze enrollment patterns, supporting university stakeholders with comprehensive reporting.</li>
            <li>Earned consecutive nominations for Student Employee of the Year in 2023 and 2024 for outstanding contributions.</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Experience;
