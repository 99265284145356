import React, { useState, useEffect } from 'react';
import './styles/AboutMe.css';
import portrait from './assets/Portrait.png';
import mailIcon from './assets/mail.png'; // Import the mail icon
import resume from './assets/Adiba_Akter_resume.pdf'; // Import the resume file

function AboutMe() {
  const [text, setText] = useState('');
  const fullText = "Hey, It's Adiba.";

  useEffect(() => {
    let index = 0;
    const typingInterval = setInterval(() => {
      setText((prev) => prev + fullText[index]);
      index++;
      if (index === fullText.length) {
        clearInterval(typingInterval);
      }
    }, 150);
    
    return () => clearInterval(typingInterval);
  }, []);

  return (
    <section className="about-me" id="about">
      <div className="about-content">
        <div className="about-text">
          <h2>{text}</h2>
          <p>
            I am a Fall 2024 graduate in Computer Science with a minor in Marketing at Sacramento State University. Currently, I'm interning as a Solutions Developer, focusing on web mapping and data visualization with ArcGIS. Passionate about coding and design, I aim to create meaningful digital experiences.
          </p>
          <a href="mailto:adiba.akter.98@gmail.com" className="say-hi-button">
            Say Hi
            <img src={mailIcon} alt="Mail Icon" className="mail-icon" />
          </a>
          <a href={resume} download className="resume-button">View My Resume</a> {/* Resume button */}
        </div>
        <div className="about-image">
          <img src={portrait} alt="Adiba Akter" />
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
