import React from 'react';
import './styles/Projects.css';

function Projects() {
  const projectData = [
    {
      title: 'MedBuzz App',
      description: 'Collaborated with a team of 8 to develop a healthcare job search platform for Radixsol, streamlining the recruitment process by enabling real-time job search, application tracking, and direct recruiter communication. Implemented key features like profile creation, ATS integration, and Microsoft SharePoint-based document management, improving efficiency and user engagement.',
      techStack: 'Tech Stack: React, Node.js, MongoDB, Microsoft SharePoint',
      githubLink: 'https://github.com/JaskirtKaler/Medbuzz'
    },
    {
      title: 'SmartPantry - Meal Planning App',
      description: 'Led back-end development for SmartPantry, a meal planning app built during HackSwift 2024, to reduce food waste by 30% and improve storage efficiency. Integrated MongoDB and Edamam API, adding features like personalized meal recommendations and a "Food Storage Tips" page, which boosted user engagement with sustainable practices.',
      techStack: 'Tech Stack: MongoDB, Edamam API, Node.js',
      githubLink: 'https://github.com/AdibaAdi/SmartPantry-MealPlanning'
    },
    {
      title: 'Scholarly WebApp',
      description: 'Led a team of 4 to create a scholarship matching platform for Sac State AI Hackathon Luma 2024. Designed a Python script for efficient scholarship data collection and utilized OpenAI API for matching scholarships to user profiles, achieving a 95% match accuracy. Achieved top 3rd position among competing teams.',
      techStack: 'Tech Stack: Python, OpenAI API, Flask, PostgreSQL',
      githubLink: 'https://github.com/AdibaAdi/AI_hackathon-Scholarly-App'
    },
    {
      title: 'Chemical Property Correlation with Wine Quality - Data Visualization',
      description: 'Developed an interactive heatmap using JavaScript and D3.js to visualize correlations between wine quality and chemical properties. Employed Pearson correlation for deeper insights, and designed a color gradient to highlight impactful properties, enhancing decision-making for stakeholders.',
      techStack: 'Tech Stack: JavaScript, D3.js, HTML, CSS',
      observableLink: 'https://observablehq.com/d/51491c41ce3da40a'
    },
    {
      title: 'Cancer Severity Analysis and Contributing Factor Analysis Dashboard',
      description: 'Created a comprehensive Tableau dashboard to analyze cancer severity and contributing factors. Integrated various visualization techniques to display complex relationships, and synthesized data insights on environmental and genetic risk factors, aiding healthcare interventions.',
      techStack: 'Tech Stack: Tableau, Python, Data Visualization',
      observableLink: 'https://observablehq.com/d/ea61c39e2bdf33ac'
    },
    {
      title: 'Socket Server Project - Real-Time Messaging System',
      description: 'Led a team in designing and implementing a robust client-server architecture using Python and Linux stream sockets, enabling real-time messaging capabilities for up to 10 clients. Spearheaded the server setup and multi-threaded handling of client connections with POSIX threads, ensuring high efficiency and scalability.',
      techStack: 'Tech Stack: Python, POSIX Threads, Linux Sockets',
      githubLink: 'https://github.com/AdibaAdi/Socket_Server_CSC138_project'
    },    
    {
      title: 'TaskMaster - Console-Based To-Do List Manager',
      description: 'Developed a console-based to-do list manager in C# using .NET, demonstrating proficiency in object-oriented programming. Implemented file I/O operations for data persistence and added task management features, enhancing user experience and showcasing problem-solving skills in a .NET environment.',
      techStack: 'Tech Stack: C#, .NET, File I/O'
    }
  ];

  return (
    <section className="projects" id="projects">
      <h2>Projects</h2>
      <div className="project-cards">
        {projectData.map((project, index) => (
          <div className="project-card" key={index}>
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <p><strong>{project.techStack}</strong></p>
            {project.githubLink && (
              <a href={project.githubLink} target="_blank" rel="noopener noreferrer">
                View on GitHub
              </a>
            )}
            {project.observableLink && (
              <a href={project.observableLink} target="_blank" rel="noopener noreferrer">
                View on ObservableHQ
              </a>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

export default Projects;
