import React from 'react';
import './styles/HonorsAndAwards.css';
import medalIcon from './assets/medal.png';

function HonorsAndAwards() {
  const honorsData = [
    {
      title: "International Student Fee Waiver for academic year 2024-2025",
      issuer: "Sac State International Student & Scholar Services",
      date: "Aug 2024",
      description: "Due to my outstanding achievements and contribution to the Sac State community, I was offered an award of 6 non-resident units per term."
    },
    {
      title: "Dr. Anne-Louise Radimsky Scholarship",
      issuer: "California State University Sacramento",
      date: "Jul 2024",
      description: "For my academic excellence and involvement in campus communities such as SWE Sac State and Data Science Club."
    },
    {
      title: "Dean's Honors List",
      issuer: "Engineering and Computer Science department",
      date: "May 2022",
      description: "Sacramento State Engineering and Computer Science department Dean’s Honor List recipient since Spring'22 to present."
    },
    {
      title: "Spring 2024 ASI Directors’ Scholarship",
      issuer: "Associated Students, Inc. of Sacramento State",
      date: "Spring 2024",
      description: "Awarded for exceptional contribution and academic achievement in the academic community."
    },
  ];

  return (
    <section className="honors-and-awards" id="honors">
      <h2>Honors & Awards</h2>
      <div className="honors-list">
        {honorsData.map((honor, index) => (
          <div className="honor-item" key={index}>
            <img src={medalIcon} alt="Medal Icon" className="honor-icon" />
            <div className="honor-details">
              <h3>{honor.title}</h3>
              <p className="issuer">
                Issued by {honor.issuer} · {honor.date}
              </p>
              <p className="description">{honor.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default HonorsAndAwards;
