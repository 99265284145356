import React from 'react';
import './styles/Achievements.css';
import nomineeIcon from './assets/ribbon.png';
import scholarIcon from './assets/learning.png';
import expertIcon from './assets/expert.png';

function Achievements() {
  return (
    <section className="achievements" id="achievements">
      <div className="achievement">
        <img src={nomineeIcon} alt="Nominee Icon" />
        <h3>Nominated as Student Employee of the Year</h3>
        <p>Recognized in both 2023 & 2024 for outstanding contributions.</p>
      </div>
      <div className="achievement">
        <img src={scholarIcon} alt="Scholar Icon" />
        <h3>Dean's Honor List Awardee</h3>
        <p>Awarded since Spring 2022 for academic excellence.</p>
      </div>
      <div className="achievement">
        <img src={expertIcon} alt="Expert Icon" />
        <h3>Proficient in Python, JavaScript, SQL, .Net and React</h3>
        <p>Skilled in programming languages and creating frameworks' solution relevant to software development.</p>
      </div>
    </section>
  );
}

export default Achievements;
